import axios from '@/axios/globalaxios'

// 查询当前周次
export const listUnapprovedTask = data =>{
    return axios({
        url: "/tradelab/openFund/listUnapprovedTask",
        method: 'post',
        data:data
    })
}

export const updateUnapprovedTask = data =>{
    return axios({
        url: "/tradelab/openFund/updateUnapprovedTask",
        method: 'post',
        data:data
    })
}

export const batchUpdateUnapprovedTask = data =>{
    return axios({
        url: "/tradelab/openFund/batchUpdateUnapprovedTask",
        method: 'post',
        data:data
    })
}